import Keycloak from 'keycloak-js';
import { REALMS_LIST, URL_BASE_KEYCLOAK, CLIENT_ID } from './config/config';

const last_client = localStorage.getItem('last_client');

let defaultRealm = 'ent_0'

const realms = JSON.parse(atob(REALMS_LIST));

if (last_client && last_client.length > 0) {
    const lastClientResult = realms.find(item => item.client === last_client);
    if (lastClientResult) {
        defaultRealm = lastClientResult.realm;
    }
}

const path = window.location.pathname;
const partPath = path.split('/');

const client = partPath[partPath.length - 1];
const result = realms.find(item => item.client === client);

const realm = result ? result.realm : defaultRealm;

const keycloakConfig = {
    url: URL_BASE_KEYCLOAK,
    realm: realm,
    clientId: CLIENT_ID,
};

let keycloak = null;

const initKeycloak = () => {
  if (keycloak) {
    return Promise.resolve(keycloak);
  }

  keycloak = new Keycloak(keycloakConfig);

  return keycloak.init({ onLoad: 'check-sso' }).then(authenticated => {
    return keycloak;
  });
};

export { initKeycloak, keycloak };
