import axios from 'axios'
import { URL_KDI_API } from '../config/config';

const api = axios.create({
  timeout: 30000,
})

const forgotPassword = (data) => {
  return api.post(URL_KDI_API + 'auth/remember', data);
};

export default forgotPassword;